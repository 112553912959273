import index from '../css/index.scss';

function ready() {
  const bgVideo = document.getElementById('bg-video');
  const smlVideo = document.getElementById('sml-video');
  const header = document.querySelector('.header');
  const subheader = document.querySelector('.subheader');
  const appleBtn = document.querySelector('.apple-btn');
  const center = document.querySelector('.center');
  const bgContainer = document.querySelector('.bg-container');
  let syncVideoID;

  function showAllelements() {
    bgContainer.classList.add("show");
    header.classList.add("show");
    subheader.classList.add("show");
    appleBtn.classList.add("show");
    center.classList.add("show");
  }

  function sync() {
    let time = smlVideo.currentTime;
    let b_time = bgVideo.currentTime;

    if (Math.abs(time - b_time) > 0.1) {
      bgVideo.currentTime = time
    }

    syncVideoID = requestAnimationFrame(sync);
  }

  function playWhenBothVideoReady() {
    bgVideo.addEventListener('loadedmetadata', () => {
      bgVideo.addEventListener('loadeddata', () => {
        bgVideo.play();
        smlVideo.play();
        showAllelements();

        // Syncronize the background video
        sync();
      })
    })
  }

  function playWhenSmallVideoReady() {
    smlVideo.addEventListener('loadedmetadata', () => {
      smlVideo.play();
      showAllelements();
    })
  }

  appleBtn.addEventListener("mouseover", () => {
    appleBtn.classList.add("hover");
  });

  let width = window.innerWidth;
  window.addEventListener('resize', () => {
    width = window.innerWidth;
  });

  if (width < 640) {
    if (smlVideo.readyState !== 4) smlVideo.load();
    playWhenSmallVideoReady()
  } else {
    if (smlVideo.readyState !== 4) smlVideo.load();
    if (bgVideo.readyState !== 4) bgVideo.load();
    playWhenBothVideoReady()
  }
}

document.addEventListener("DOMContentLoaded", ready);
